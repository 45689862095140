<template>

  <div class="category-editor" v-show="processTypes && processTypes.length >= 1">
    <div class="category-input-group">
      <vx-input-group class="mb-0">
        <vs-input placeholder="Beschreibung (optional)" v-model="newProcessType.description" />
        <template slot="prepend">
          <vs-input placeholder="Name" v-model="newProcessType.name" />
        </template>
        <template slot="append">
          <vs-button color="primary" @click="addProcessType" icon="add"></vs-button>
        </template>
      </vx-input-group>
    </div>
    <div class="category-list">
      <div
          class="category-item"
          v-for="(processType, index) in processTypes"
          @dblclick="onDoubleClick(index, processType)"
          :class="index !== currentEditTarget && processType.parent_id ? 'child-type' : ''"
      >
        <div class="category-item__label">
          <template v-if="index === currentEditTarget">
            <vs-input size="small" v-model="editClone.name" style="width: 25%"></vs-input>
            <vs-input size="small" v-model="editClone.description" style="width: 35%" class="ml-2"></vs-input>
            <vs-input size="small" v-model="editClone.technical_name" style="width: 35%" class="ml-2"></vs-input>
            <span><vs-input size="small" v-model="editClone.parent_id" style="width: 20%" placeholder="Parent ID"></vs-input></span>
          </template>
          <template v-else>
            <span class="name">{{ processType.name }}</span>
            <span class="description">{{ processType.description }}</span>
            <span v-if="processType.parent_id" class="parent-id" style="min-width: 30px">Parent ID: {{ processType.parent_id }}</span>
          </template>

          <template v-if="index === currentEditTarget">
                        <span class="process-type-item__label-delete" @click="currentEditTarget=null">
                            <vs-icon icon="close"></vs-icon>
                        </span>
            <span class="process-type-item__label-check" @click="saveProcessType">
                            <vs-icon icon="done"></vs-icon>
                        </span>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "../../api";

export default {
  name: "ProcessTypeEditor",
  data() {
    return {
      initialProcessTypes: [],
      processTypes: [],
      currentEditTarget: null,
      editClone: null,
      newProcessType: {
        name: null,
        description: null,
        parent_id: null,
        technical_name: null,
      },
    };
  },

  created() {
    this.fetchProcessTypes();
  },

  methods: {
    fetchProcessTypes() {
      ApiService.get("process-type").then((response) => {
        if (response.data.status !== "success") {
          return;
        }
        this.$emit("fetched");
        this.processTypes = response.data.result;
        this.initialProcessTypes = response.data.result;
      });
    },
    addProcessType() {
      ApiService.post("process-type", this.newProcessType)
          .then(() => {
            this.fetchProcessTypes();
            this.newProcessType = {
              name: null,
              description: null,
              parent_id: null,
              technical_name: null,
            };

            this.$vs.notify({
              title: "Erfolgreich",
              text: "Der Prozesstyp wurde erfolgreich gespeichert",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "success",
            });
          })
          .catch((error) => {
            this.handleError(error);
          });
    },

    saveProcessType() {
      ApiService.put("process-types/" + this.editClone.id, this.editClone)
          .then(() => {
            this.fetchProcessTypes();

            this.currentEditTarget = null;

            this.$vs.notify({
              title: "Erfolgreich",
              text: "Der Prozesstyp wurde erfolgreich gespeichert",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "success",
            });
          })
          .catch((error) => {
            this.currentEditTarget = null;
            this.handleError(error);
          });
    },

    onDoubleClick(index, processType) {
      this.currentEditTarget = index;
      this.editClone = JSON.parse(JSON.stringify(processType));
    },

    handleError(error) {
      if (error.response.data.message) {
        this.$vs.notify({
          title: "Fehler",
          text: error.response.data.message,
          time: 6000,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
      } else {
        this.$vs.notify({
          title: "Fehler",
          text: error.message,
          time: 6000,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.process-type-item.child-type {
  color: #b4b4b4;
}
</style>
