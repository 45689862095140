<template>
  <div class="settings-detail detail-process-types">
    <div id="settings-detail--loader" class="vs-con-loading__container overflow-visible">
      <div class="detail-process-types-content">
        <div class="w-full" v-show="fetched">
          <div class="p-6 flex flex-col justify-center w-full m-auto" style="max-width: 800px;">
            <p class="text-md text-left w-full mb-3 uppercase font-bold">Prozesstypen</p>
            <process-type-editor class="w-full mb-6" @fetched="typesLoaded=true"></process-type-editor>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProcessTypeEditor from "@/components/search/ProcessTypeEditor.vue";

export default {
  name: "ProcessType",
  components: { ProcessTypeEditor },
  data() {
    return {
      scrollbarSettings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.30,
      },
      typesLoaded: false,
    };
  },
  computed: {
    fetched() {
      return this.typesLoaded;
    }
  },
  mounted() {
    this.openLoading();
  },
  methods: {
    openLoading() {
      this.$vs.loading({ container: '#settings-detail--loader', scale: 0.6 });
    },

    closeLoading() {
      this.$vs.loading.close('#settings-detail--loader > .con-vs-loading');
    },
  },
  watch: {
    fetched(value) {
      if (value) {
        this.closeLoading();
      }
    }
  }
};
</script>

<style scoped>

</style>
