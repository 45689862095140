import General from './components/General';
import CategoriesTags from './components/CategoriesTags';
import WorkTimeSettings from './components/WorkTimeSettings';
import CostCenter from './components/CostCenter';
import ProcessType from "./components/ProcessType.vue";

const MenuItems = [
    { label: 'Allgemeines', name: 'general', icon: 'SettingsIcon', component: General },
    { label: 'Kategorien & Tags', name: 'categories-tags', icon: 'ListIcon', component: CategoriesTags },
    { label: 'Kostenstellen', name: 'cost-center', icon: 'ListIcon', component: CostCenter },
    { label: 'Arbeitszeiteinstellungen', name: 'absence-types', icon: 'ClockIcon', component: WorkTimeSettings },
    { label: 'Vorgangsarten', name: 'process-type', icon: 'ListIcon', component: ProcessType },

];

export default MenuItems;